import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { ColDef } from 'ag-grid-community'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { DEFAULT_ROW_HEIGHT, EXTENDED_ROW_HEIGHT, Table } from 'components/common/table'
import { renderCompareColumn } from 'components/merge/table/utils/RenderCompareColumn'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { useTableNoMergeRowsOverlay } from 'pages/hooks/useTableNoMergeRowsOverlay'
import { MarketTableRowField, MergeMarketsValues } from 'pages/markets/merge/form/types'
import { useGetParentIdName } from 'pages/markets/merge/useGetParentIdName'
import { ENTRY_NAME, MergeCardProps } from 'types/masterData/mergeEntities'

export const CompareEntities = ({ twoEntityValues, data, ...rest }: MergeCardProps<MergeMarketsValues>) => {
  const { t } = useTranslation()
  const names = twoEntityValues.fields.name
  const firstEntityName = names[0]
  const secondEntityName = names[1]
  const tableNoMergeRowsOverlay = useTableNoMergeRowsOverlay()
  const parentIdName = useGetParentIdName({ data })

  const columnDefs = useMemo<ColDef<MarketTableRowField>[]>(
    () => [
      {
        flex: 0.8,
        headerName: t('master_data.merge_entities.table.columns.parameter'),
        cellRenderer: ({ data }: { data: MarketTableRowField }) => {
          if (data.index !== 0) return null
          const isParentId = data.id === 'parentId'
          return (
            <WppTypography type="s-body">
              {isParentId
                ? parentIdName
                : t(`master_data.merge_entities.fields.${data.id}`, {
                    entity: t('common.master_data.lowercase_entities.market'),
                  })}
            </WppTypography>
          )
        },
      },
      {
        flex: 1,
        headerName: firstEntityName,
        cellRenderer: renderCompareColumn({ columnId: ENTRY_NAME[0], formIndex: 0 }),
      },
      {
        flex: 1,
        headerName: secondEntityName,
        cellRenderer: renderCompareColumn({ columnId: ENTRY_NAME[1], formIndex: 1 }),
      },
    ],
    [firstEntityName, secondEntityName, t, parentIdName],
  )

  return (
    <WppCard {...rest} data-testid="compare-entities">
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.select_values', { order: 2 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.select_values_markets')}
      </WppTypography>

      <Table
        noRowsOverlayComponent={tableNoMergeRowsOverlay}
        domLayout={twoEntityValues.tableRowDataFields.length === 0 ? 'normal' : 'autoHeight'}
        columnDefs={columnDefs}
        rowData={twoEntityValues.tableRowDataFields}
        getRowId={params => `${params.data.id}-${params.data.index}`}
        getRowHeight={params => (params.data?.id === 'logoThumbnail' ? EXTENDED_ROW_HEIGHT : DEFAULT_ROW_HEIGHT)}
        className={clsx({
          [styles.emptyTable]: twoEntityValues.tableRowDataFields.length === 0,
        })}
      />
    </WppCard>
  )
}
