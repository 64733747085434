import { WppActionButton, WppButton, WppIconTrash } from '@platform-ui-kit/components-library-react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCreateBrandApi } from 'api/masterData/mutations/useCreateBrandApi'
import { useEditBrandApi } from 'api/masterData/mutations/useEditBrandApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { showDeleteBrandModal } from 'pages/brands/deleteBrandModal/DeleteBrandModal'
import { convertFormToFormDto } from 'pages/brands/upsert/utils'
import { handleReloadBrandsListTable } from 'pages/brands/utils'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Brand, BrandForm } from 'types/masterData/brands'
import { routesManager } from 'utils/routesManager'

interface Props {
  brand: Brand
  form: UseFormReturn<BrandForm>
}

export const ActionButtons = ({ brand, form }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const {
    contextState: { nonCurrentVersionSelected },
    handleSelectVersion,
  } = useUpsertEntityContext()

  const { mutateAsync: handleCreate } = useCreateBrandApi()
  const { mutateAsync: handleEdit } = useEditBrandApi()
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: BrandForm) => {
    try {
      const { data: createdBrand } = await handleCreate({
        brand: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.brands.update.getURL({ entryId: createdBrand?.id }))

      handleSelectVersion({ version: null })
      await Promise.all([
        handleReloadBrandsListTable(brand?.id),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY] }),
      ])

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.brand') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: BrandForm) => {
    try {
      await handleEdit({
        id: brand!.id,
        brand: convertFormToFormDto(values),
      })

      handleSelectVersion({ version: null })
      await Promise.all([
        handleReloadBrandsListTable(brand!.id),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY] }),
      ])

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.brand') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteBrandModal({
      brand: brand!,
      onAfterDelete: () => {
        navigate(routesManager.masterData.brands.root.getURL())
      },
    })
  }

  return (
    <>
      {brand && (
        <WppActionButton
          variant="destructive"
          onClick={handleDelete}
          disabled={nonCurrentVersionSelected}
          data-testid="delete-brand"
        >
          <WppIconTrash slot="icon-start" />
          {t('common.delete')}
        </WppActionButton>
      )}
      <WppButton
        variant="secondary"
        disabled={!isDirty || nonCurrentVersionSelected}
        onClick={() => reset()}
        data-testid="reset-brand"
      >
        {t('common.reset')}
      </WppButton>
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(brand ? onEditSubmit : onCreateSubmit)()}
        disabled={nonCurrentVersionSelected}
        data-testid="save-brand"
      >
        {t('common.save')}
      </WppButton>
    </>
  )
}
