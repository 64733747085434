import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFetchCurrencyApi } from 'api/masterData/queries/useFetchCurrencyApi'
import { useForm } from 'hooks/form/useForm'
import { QueryFn, useFetchTwoEntities } from 'hooks/masterData/useFetchTwoEntities'
import { MergeEntityWrapper } from 'pages/components/mergeEntityWrapper/MergeEntityWrapper'
import { ActionButtons } from 'pages/currencies/merge/components/ActionButtons'
import { CompareEntities } from 'pages/currencies/merge/components/CompareEntities'
import { PreviewResult } from 'pages/currencies/merge/components/PreviewResult'
import { SelectMainEntity } from 'pages/currencies/merge/components/SelectMainEntity'
import { defaultFormValues } from 'pages/currencies/merge/form/defaultValues'
import { useCurrenciesMergeValidationScheme } from 'pages/currencies/merge/form/formValidation'
import { MergeCurrenciesValues } from 'pages/currencies/merge/form/types'
import { defaultTwoEntityValues } from 'pages/currencies/merge/twoEntities/defaultValues'
import { convertToTwoEntityValues } from 'pages/currencies/merge/twoEntities/utils'
import { convertToForm } from 'pages/currencies/merge/utils'
import { Currency } from 'types/masterData/currencies'

export const MergeCurrencies = () => {
  const { t } = useTranslation()
  const fetchCurrencies = useFetchTwoEntities<Currency, MergeCurrenciesValues>({
    queryFn: useFetchCurrencyApi as QueryFn<Currency>,
    getValuesFn: ({ isSuccess, data }) => (isSuccess ? convertToTwoEntityValues(data) : defaultTwoEntityValues),
  })
  const { data, isSuccess } = fetchCurrencies

  const form = useForm({
    defaultValues: isSuccess ? convertToForm(data) : defaultFormValues,
    validationSchema: useCurrenciesMergeValidationScheme(),
  })

  return (
    <FormProvider {...form}>
      <MergeEntityWrapper
        title={t('master_data.merge_entities.merge_title', {
          entity: t('common.master_data.lowercase_entities.currency_other'),
        })}
        actionButtons={<ActionButtons data={data} form={form} />}
        fetchEntities={fetchCurrencies}
        selectMainEntity={SelectMainEntity}
        previewResult={PreviewResult}
        compareEntities={CompareEntities}
        data-testid="merge-entity-wrapper"
      />
    </FormProvider>
  )
}
