import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { useMemo } from 'react'

import { useInfiniteClientsApi } from 'api/masterData/infiniteQueries/useInfiniteClientsApi'

const LOAD_ALL_PAGE_SIZE = 1000

interface Props {
  entry1Id?: string
  entry2Id?: string
  loadClientsEnabled: boolean
}

export const useClientsDataLoading = ({ entry1Id, entry2Id, loadClientsEnabled }: Props) => {
  const { data, isLoading, isSuccess } = useInfiniteClientsApi({
    enabled: loadClientsEnabled && !!entry1Id && !!entry2Id,
    params: {
      page: 1,
      agencyId: [entry1Id!, entry2Id!],
      itemsPerPage: LOAD_ALL_PAGE_SIZE,
    },
  })

  return useMemo(() => {
    return {
      isClientsDataLoading: isLoading,
      isClientsDataSuccess: isSuccess,
      clientsData: data
        .filter(item => !item.deletedAt)
        .map(item => ({ id: item.id, label: item.name }) as AutocompleteDefaultOption),
    }
  }, [data, isLoading, isSuccess])
}
