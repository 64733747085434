import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { EntityLogo } from 'pages/components/mergeEntityWrapper/components/EntityLogo'
import { EntityPills } from 'pages/components/mergeEntityWrapper/components/EntityPills'
import { EntityTextPreview } from 'pages/components/mergeEntityWrapper/components/EntityTextPreview'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { MergeGeographiesForm } from 'pages/geographies/merge/form/types'
import { convertFormToPreview } from 'pages/geographies/merge/preview-utils'
import { Geography } from 'types/masterData/geographies'
import { MergeCardProps } from 'types/masterData/mergeEntities'

export const PreviewResult = ({ data, ...rest }: MergeCardProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<MergeGeographiesForm>()
  const formValues = watch()

  const preview = convertFormToPreview({ values: formValues, data: data as Geography[] })

  return (
    <WppCard {...rest} data-testid="preview-result">
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.preview', { order: 3 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.preview')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        <EntityLogo src={preview.logoThumbnail?.url!} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.entity_name', {
            entity: t('common.master_data.entities.geography'),
          })}
          text={preview.name!}
          hasEmptyState
          data-testid="preview-name-field"
        />

        <EntityPills
          data={preview.aliases!}
          label={t('master_data.merge_entities.fields.aliases')}
          data-testid="preview-aliases-pills"
        />
      </Flex>
    </WppCard>
  )
}
