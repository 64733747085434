import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFetchClientApi } from 'api/masterData/queries/useFetchClientApi'
import { useForm } from 'hooks/form/useForm'
import { QueryFn, useFetchTwoEntities } from 'hooks/masterData/useFetchTwoEntities'
import { ActionButtons } from 'pages/clients/merge/components/ActionButtons'
import { CompareEntities } from 'pages/clients/merge/components/CompareEntities'
import { PreviewResult } from 'pages/clients/merge/components/PreviewResult'
import { SelectMainEntity } from 'pages/clients/merge/components/SelectMainEntity'
import { defaultFormValues } from 'pages/clients/merge/form/defaultValues'
import { useClientsMergeValidationScheme } from 'pages/clients/merge/form/formValidation'
import { MergeClientsValues } from 'pages/clients/merge/form/types'
import { defaultTwoEntityValues } from 'pages/clients/merge/twoEntities/defaultValues'
import { convertToTwoEntityValues } from 'pages/clients/merge/twoEntities/utils'
import { convertToForm } from 'pages/clients/merge/utils'
import { MergeEntityWrapper } from 'pages/components/mergeEntityWrapper/MergeEntityWrapper'
import { Client } from 'types/masterData/clients'

export const MergeClients = () => {
  const { t } = useTranslation()
  const fetchClients = useFetchTwoEntities<Client, MergeClientsValues>({
    queryFn: useFetchClientApi as QueryFn<Client>,
    getValuesFn: ({ isSuccess, data }) => (isSuccess ? convertToTwoEntityValues(data) : defaultTwoEntityValues),
  })
  const { data, isSuccess } = fetchClients

  const form = useForm({
    defaultValues: isSuccess ? convertToForm(data) : defaultFormValues,
    validationSchema: useClientsMergeValidationScheme(),
  })

  return (
    <FormProvider {...form}>
      <MergeEntityWrapper
        title={t('master_data.merge_entities.merge_title', {
          entity: t('common.master_data.lowercase_entities.client_other'),
        })}
        actionButtons={<ActionButtons data={data} form={form} />}
        fetchEntities={fetchClients}
        selectMainEntity={SelectMainEntity}
        previewResult={PreviewResult}
        compareEntities={CompareEntities}
        data-testid="merge-entity-wrapper"
      />
    </FormProvider>
  )
}
