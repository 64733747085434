import {
  WppActionButton,
  WppAvatar,
  WppIconChevron,
  WppListItem,
  WppSpinner,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentProps, useCallback, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { useAsyncFn, useSetState } from 'react-use'

import { useInfiniteEntityChangeHistoryApi } from 'api/masterData/infiniteQueries/useInfiniteEntityChangeHistoryApi'
import { useFetchEntityApi } from 'api/masterData/queries/useFetchEntityApi'
import { useFetchVersionedEntityApi } from 'api/masterData/queries/useFetchVersionedEntityApi'
import { Flex } from 'components/common/flex/Flex'
import { showUnsavedConfirmationModal } from 'components/unsavedConfirmationModal/UnsavedConfirmationModal'
import { useDateFormat } from 'hooks/useDateFormat'
import styles from 'pages/components/entityChangeHistory/EntityChangeHistory.module.scss'
import { EntityChangeHistorySkeleton } from 'pages/components/entityChangeHistory/EntityChangeHistorySkeleton'
import { convertToFormObj, getUpdatedByLabel } from 'pages/components/entityChangeHistory/utils'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { SelectVersionProps } from 'pages/components/upsertEntityWrapper/components/UpsertEntityProvider'
import { useToast } from 'providers/toast/ToastProvider'
import { ChangeHistory } from 'types/masterData/changeHistory'
import { RouteModules } from 'types/masterData/utils'

interface Props extends ComponentProps<typeof Flex> {}

export const CHANGE_HISTORY_SIZE = 3
export const EntityChangeHistory = ({ ...rest }: Props) => {
  const {
    contextState: { selectedVersion },
    handleSelectVersion,
  } = useUpsertEntityContext()
  const {
    reset,
    formState: { isDirty },
  } = useFormContext()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { entryId } = useParams()
  const { enqueueToast } = useToast()
  const { formatDate } = useDateFormat()
  const moduleName = pathname?.split('/')?.[1] as RouteModules
  const [{ isCollapsed, isTouched }, setState] = useSetState({
    isCollapsed: false,
    isTouched: false,
  })

  const { data, isLoading, isFetching, response, fetchNextPage, hasNextPage, isError } =
    useInfiniteEntityChangeHistoryApi({
      params: {
        module: moduleName,
        entryId: entryId!,
        itemsPerPage: CHANGE_HISTORY_SIZE,
        page: 1,
      },
      enabled: !!entryId,
      isCollapsed: isCollapsed || !isTouched,
    })

  const hasSecondPage = !!response?.pages?.length && response.pages.length > 1

  useEffect(() => {
    if (isError) {
      enqueueToast({
        message: t('common.errors.general'),
        type: 'error',
      })
    }
  }, [isError, enqueueToast, t])

  const fetchVersionedEntity = useFetchVersionedEntityApi({
    staleTime: 0,
  })

  const fetchCurrentVersion = useFetchEntityApi({
    staleTime: 0,
  })

  const getVersionData = useCallback(
    async (versionIdToFetch: string, loadCurrentVersion: boolean) => {
      if (loadCurrentVersion) {
        const response = await fetchCurrentVersion({
          module: moduleName,
          entryId: entryId!,
        })
        return response.data
      }
      const response = await fetchVersionedEntity({
        module: moduleName,
        entryId: entryId!,
        versionId: versionIdToFetch!,
      })
      return response.data
    },
    [moduleName, entryId, fetchCurrentVersion, fetchVersionedEntity],
  )

  const [{ loading: isVersionLoading }, handleVersionApply] = useAsyncFn(
    async ({ version }: SelectVersionProps) => {
      handleSelectVersion({ version })
      const isUnchecking = version?.id === selectedVersion?.id
      const noNeedToFetch = version?.isCurrentVersion && (isUnchecking || !selectedVersion)
      if (noNeedToFetch) return

      try {
        const loadCurrentVersion = version?.isCurrentVersion || isUnchecking
        const data = await getVersionData(version?.id!, loadCurrentVersion)

        const formData = convertToFormObj[moduleName](data as any)
        reset(formData)
      } catch {
        enqueueToast({
          message: t('common.errors.general'),
          type: 'error',
        })
      }
    },
    [selectedVersion, handleSelectVersion, moduleName, t, enqueueToast, reset, getVersionData],
  )

  const handleVersionClick = useCallback(
    ({ version }: SelectVersionProps) => {
      const isAlreadySelected = selectedVersion?.id === version?.id
      const shouldBeConfirmed = !version?.isCurrentVersion && isDirty && !isAlreadySelected

      if (shouldBeConfirmed) {
        showUnsavedConfirmationModal({ onSubmit: () => handleVersionApply({ version }) })
      } else {
        handleVersionApply({ version })
      }
    },
    [isDirty, handleVersionApply, selectedVersion],
  )

  const isCachedMultiPageResult = hasSecondPage && !isTouched
  const shouldShowMoreButton = hasNextPage || isCachedMultiPageResult || isCollapsed
  const shouldShowHideButton = !isCollapsed && isTouched

  if (isLoading) {
    return <EntityChangeHistorySkeleton />
  }

  if (data.length === 0) {
    return <WppTypography type="s-body">{t('change_history.empty_state')}</WppTypography>
  }

  return (
    <Flex direction="column" gap={8} align="center" data-testid="entity-change-history" {...rest}>
      {data.map((item, index) => (
        <WppListItem
          key={item.id}
          value={item}
          disabled={isVersionLoading}
          onWppChangeListItem={({ detail }) => handleVersionClick({ version: detail.value as ChangeHistory })}
          className={styles.listItem}
          checked={selectedVersion?.id === item.id}
          data-testid="change-history-list-item"
        >
          <span slot="label">
            {index === 0
              ? t('change_history.versions.current')
              : t('change_history.versions.version_number', { number: item.index })}
          </span>
          <span slot="caption">
            {getUpdatedByLabel(item.updatedBy)
              ? t('change_history.versions.updated_by_username', {
                  username: getUpdatedByLabel(item.updatedBy),
                  updatedAt: formatDate(item?.updatedAt!),
                })
              : t('change_history.versions.updated_at', {
                  updatedAt: formatDate(item?.updatedAt!),
                })}
          </span>
          <WppAvatar size="s" name={getUpdatedByLabel(item.updatedBy, item.index)} slot="left" />
          {isVersionLoading && selectedVersion?.id === item.id && <WppSpinner slot="right" />}
        </WppListItem>
      ))}
      <Flex
        gap={12}
        className={clsx(styles.actionButtons, {
          [styles.hidden]: !shouldShowMoreButton && !shouldShowHideButton,
        })}
        data-testid="change-history-list-navigation"
      >
        <WppActionButton
          loading={isFetching && !isCachedMultiPageResult}
          className={clsx({
            [styles.hidden]: !shouldShowMoreButton,
          })}
          onClick={() => {
            setState({ isCollapsed: false, isTouched: true })
            hasNextPage && fetchNextPage()
          }}
          data-testid="show-more"
        >
          <WppIconChevron direction="down" slot="icon-start" />
          {t('common.show_more')}
        </WppActionButton>

        <WppActionButton
          disabled={isFetching}
          className={clsx({
            [styles.hidden]: !shouldShowHideButton,
          })}
          onClick={() => setState({ isCollapsed: true, isTouched: true })}
          data-testid="show-less"
        >
          <WppIconChevron direction="top" slot="icon-start" />
          {t('common.show_less')}
        </WppActionButton>
      </Flex>
    </Flex>
  )
}
