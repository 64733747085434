import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormCheckbox } from 'components/form/formCheckbox/FormCheckbox'
import { FormRadioGroup } from 'components/form/formRadioGroup/FormRadioGroup'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { MergeMarketsForm } from 'pages/markets/merge/form/types'
import { MergeCardProps } from 'types/masterData/mergeEntities'

export const SelectMainEntity = ({ twoEntityValues, ...rest }: MergeCardProps) => {
  const { t } = useTranslation()
  const { getValues, clearErrors } = useFormContext<MergeMarketsForm>()
  const [isNewName, name] = getValues(['isNewName', 'name'])

  return (
    <WppCard {...rest} data-testid="select-main-entity">
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.select_main_entity', { order: 1 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.main_entity')}
      </WppTypography>

      <Flex direction="column" gap={16}>
        <FormRadioGroup name="name" values={twoEntityValues.fields.name} />
        <Flex gap={16} align="center">
          <FormCheckbox
            name="isNewName"
            onChange={event => {
              if (!event.detail.checked) clearErrors('newName')
            }}
            labelConfig={{ text: t('master_data.merge_entities.fields.new_name') }}
            required
          />
          <FormTextInput
            size="s"
            name="newName"
            disabled={!isNewName}
            required
            placeholder={name}
            className={styles.nameField}
          />
        </Flex>
      </Flex>
    </WppCard>
  )
}
