import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { SchemeAdjustments } from 'pages/markets/upsert/UpsertMarket'
import { Market, MarketForm, MarketFormDTO, MarketType } from 'types/masterData/markets'
import { uppercaseRegExp } from 'utils/regexp'
import { autocompleteOptionType } from 'utils/zod'

export const getDefaultValues = (values: Partial<MarketForm>): MarketForm => ({
  name: '',
  type: MarketType.COUNTRY,
  aliases: [],
  isoAlpha2: '',
  isoAlpha3: '',
  geographyIds: [],
  currencyIds: [],
  parentId: null,
  stateOfCountry: null,
  logoThumbnail: null,
  logoOriginal: null,
  meta: null,
  ...values,
})

export const getStateCountryValues = (values: Market): Pick<MarketForm, 'parentId'> => ({
  parentId: values?.parent ? { id: values.parent.id, name: values.parent.name } : null,
})

export const getCityCountryValues = (values: Market): Pick<MarketForm, 'parentId' | 'stateOfCountry'> => {
  if (!values.hierarchy?.length) {
    return {
      parentId: null,
      stateOfCountry: null,
    }
  }
  const hasCityAndState = values.hierarchy.length > 1
  if (hasCityAndState) {
    return {
      parentId: { id: values.hierarchy[1].id, name: values.hierarchy[1].name },
      stateOfCountry: { id: values.hierarchy[0].id, name: values.hierarchy[0].name },
    }
  }
  return {
    parentId: {
      id: values.hierarchy[0].id,
      name: values.hierarchy[0].name,
    },
    stateOfCountry: null,
  }
}

export const convertToForm = (values: Market): MarketForm =>
  values && {
    ...values,
    parentId: null,
    stateOfCountry: null,
    ...(values.type === MarketType.STATE && getStateCountryValues(values)),
    ...(values.type === MarketType.CITY && getCityCountryValues(values)),
    geographyIds: (values.geographies || []).map(item => ({ id: item.id, label: item.name })),
    currencyIds: (values.currencies || []).map(item => ({ id: item.id, label: item.name })),
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
    meta: values?.meta ? JSON.stringify(values.meta) : null,
  }

export const getParentIdFromForm = ({ type, parentId, stateOfCountry }: MarketForm): MarketFormDTO['parentId'] => {
  if (type === MarketType.COUNTRY) {
    return null
  }
  if (type === MarketType.STATE) {
    return parentId?.id!
  }
  return stateOfCountry?.id! || parentId?.id!
}

export const convertFormToFormDto = (values: MarketForm): MarketFormDTO => {
  const { stateOfCountry, parentId, currencyIds, ...formFields } = values
  const isCountry = formFields.type === MarketType.COUNTRY

  return {
    ...formFields,
    aliases: values.aliases.map(item => item.id.toString()),
    geographyIds: values.geographyIds.map(item => item.id.toString()),
    ...(isCountry ? { currencyIds: values?.currencyIds?.map(item => item.id.toString()) } : {}),
    parentId: getParentIdFromForm(values),
  }
}

export const nameFieldCharacterMaxCount = 256
export const nameFieldCharacterMinCount = 2
export const isoAlpha2FieldCharacterCount = 2
export const isoAlpha3FieldCharacterCount = 3
export const aliasesFieldCharacterMinCount = 2
export const aliasesFieldCharacterMaxCount = 100

export const useMarketValidationScheme = ({ selectedCountryHasStates, selectedType }: SchemeAdjustments) => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod
        .object({
          logoOriginal: zod
            .object({
              key: zod.string(),
              name: zod.string(),
              size: zod.number(),
            })
            .nullable(),
          logoThumbnail: zod
            .object({
              key: zod.string(),
              name: zod.string(),
              size: zod.number(),
            })
            .nullable(),
          name: zod
            .string()
            .trim()
            .min(
              nameFieldCharacterMinCount,
              t('master_data.validation.name.min', {
                entity: t('common.master_data.entities.market'),
                characterCount: nameFieldCharacterMinCount,
              }),
            )
            .max(
              nameFieldCharacterMaxCount,
              t('master_data.validation.name.max', {
                entity: t('common.master_data.entities.market'),
                characterCount: nameFieldCharacterMaxCount,
              }),
            ),
          ...(selectedType === MarketType.COUNTRY && {
            isoAlpha2: zod
              .string()
              .trim()
              .regex(uppercaseRegExp, t('master_data.validation.iso_alpha_2.uppercase'))
              .min(
                isoAlpha2FieldCharacterCount,
                t('master_data.validation.iso_alpha_2.length', {
                  entity: t('common.master_data.entities.market'),
                  characterCount: isoAlpha2FieldCharacterCount,
                }),
              )
              .max(
                isoAlpha2FieldCharacterCount,
                t('master_data.validation.iso_alpha_2.length', {
                  entity: t('common.master_data.entities.market'),
                  characterCount: isoAlpha2FieldCharacterCount,
                }),
              ),
            isoAlpha3: zod
              .string()
              .trim()
              .regex(uppercaseRegExp, t('master_data.validation.iso_alpha_3.uppercase'))
              .min(
                isoAlpha3FieldCharacterCount,
                t('master_data.validation.iso_alpha_3.length', {
                  entity: t('common.master_data.entities.market'),
                  characterCount: isoAlpha3FieldCharacterCount,
                }),
              )
              .max(
                isoAlpha3FieldCharacterCount,
                t('master_data.validation.iso_alpha_3.length', {
                  entity: t('common.master_data.entities.market'),
                  characterCount: isoAlpha3FieldCharacterCount,
                }),
              ),
          }),
          parentId: zod
            .object({
              id: zod.string(),
              name: zod.string(),
            })
            .nullable(),
          stateOfCountry: zod
            .object({
              id: zod.string(),
              name: zod.string(),
            })
            .nullable(),
          type: zod.string(),
          geographyIds: zod.array(zod.union([autocompleteOptionType, zod.string()])),
          currencyIds: zod.array(zod.union([autocompleteOptionType, zod.string()])),
          aliases: zod.array(
            zod.object({
              id: zod
                .string()
                .min(aliasesFieldCharacterMinCount, {
                  message: t('master_data.validation.aliases.min', {
                    characterCount: aliasesFieldCharacterMinCount,
                  }),
                })
                .max(aliasesFieldCharacterMaxCount, {
                  message: t('master_data.validation.aliases.max', {
                    characterCount: aliasesFieldCharacterMaxCount,
                  }),
                }),
              label: zod.string(),
            }),
          ),
        })
        .refine(
          ({ parentId }) => {
            const isStateOrCity = [MarketType.STATE, MarketType.CITY].includes(selectedType)
            return !(isStateOrCity && !parentId)
          },
          {
            message: t('master_data.validation.country.required'),
            path: ['parentId'],
          },
        )
        .refine(
          ({ stateOfCountry }) => {
            const isCity = selectedType === MarketType.CITY
            return !(isCity && selectedCountryHasStates && !stateOfCountry)
          },
          {
            message: t('master_data.validation.state.required'),
            path: ['stateOfCountry'],
          },
        ),
    [t, selectedCountryHasStates, selectedType],
  )
}
