import { MayBeNull } from '@wpp-open/core'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

export const unknown_field_error = 'unknown_field_error' as const
export enum ErrorCodes {
  INVALID_PARENT_NAME = 'invalid_parent_name',
  EXISTS_IN_DATABASE_BY_ALIAS = 'exists_in_database_by_alias',
  INVALID_CSV_FORMAT = 'invalid_csv_format',
  FILE_IS_NOT_CSV = 'file_is_not_csv',
  MISSING_REQUIRED_COLUMNS = 'missing_required_columns',
  EXTRA_COLUMNS = 'extra_columns',
  TOO_MUCH_CSV_ROWS = 'too_much_csv_rows',
  PARENT_HAD_ERRORS = 'parent_had_errors',
  DUPLICATE_VALUE = 'duplicate_value',
  INTEGRITY_ERROR = 'integrity_error',
  INVALID_ID = 'invalid_id',
  VALIDATION_ERROR = 'validation_error',
  NONE_NOT_ALLOWED = 'none_not_allowed',
  MAX_ITEMS_IN_LIST = 'max_items_in_list',
  INVALID_UUID = 'invalid_uuid',
  MIN_LENGTH_ERROR = 'min_length_error',
  MAX_LENGTH_ERROR = 'max_length_error',
  'value_error.extra' = 'value_error.extra',
}

export interface ImportError {
  line: MayBeNull<number>
  type: keyof typeof ErrorCodes
  detail: MayBeNull<string>
}

export interface ImportEntityForm {
  file: File[]
}

export interface ImportEntityResponse {
  errors: ImportError[]
  importedLines: number
}

export const defaultValues: ImportEntityForm = {
  file: [],
}

export const parseDetail = (detail: MayBeNull<string>, t: TFunction) => {
  if (!detail) return ''
  if (/{.+}/.test(detail)) {
    return t('import.errors.detail_format', {
      detail: detail
        ?.replace(/{'|'}|'/g, '')
        .split(',')
        .map(item => item.trim())
        .join(', '),
    })
  }
  return t('import.errors.detail_format', { detail })
}

export const handleGroupErrorLines = (response: ImportError[] = []): ImportError[][] =>
  Array.from(
    response.reduce((accum, currentError) => {
      const line = currentError.line || -1
      const lineError = accum.get(line)
      accum.set(line, !lineError ? [currentError] : [...lineError, currentError])
      return accum
    }, new Map<number, ImportError[]>()),
    ([_, value]) => value,
  ).sort((a, b) => (a[0].line || 0) - (b[0].line || 0))

const ACCEPTED_IMAGE_TYPES = ['text/csv']
export const MAX_FILE_SIZE = 1

const sizeInMB = (sizeInBytes: number, decimalsNum = 2) => {
  const result = sizeInBytes / (1024 * 1024)
  return +result.toFixed(decimalsNum)
}

export const useImportValidationScheme = () => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      zod.object({
        file: zod
          .custom<FileList>()
          .refine(files => Array.from(files ?? []).length !== 0, t('import.validation.file_required'))
          .refine(
            files => Array.from(files ?? []).every(file => sizeInMB(file.size) <= MAX_FILE_SIZE),
            t('import.validation.file_size', { size: MAX_FILE_SIZE }),
          )
          .refine(
            files => Array.from(files ?? []).every(file => ACCEPTED_IMAGE_TYPES.includes(file.type)),
            t('import.validation.file_type'),
          ),
      }),
    [t],
  )
}
