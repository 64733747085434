import { WppActionButton, WppIconExport } from '@platform-ui-kit/components-library-react'
import { AxiosResponse } from 'axios'
import { ComponentProps, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useAsyncFn } from 'react-use'

import { createUseFetch } from 'api/common/createUseFetch'
import { Params as AgenciesParams } from 'api/masterData/fetchers/fetchAgenciesExportApi'
import { Params as BrandsParams } from 'api/masterData/fetchers/fetchBrandsExportApi'
import { Params as ClientsParams } from 'api/masterData/fetchers/fetchClientsExportApi'
import { Params as GeographiesParams } from 'api/masterData/fetchers/fetchGeographiesExportApi'
import { Params as IndustriesParams } from 'api/masterData/fetchers/fetchIndustriesExportApi'
import { Params as MarketsParams } from 'api/masterData/fetchers/fetchMarketsExportApi'
import { useToast } from 'providers/toast/ToastProvider'
import { FilterState } from 'types/masterData/utils'
import { routesManager } from 'utils/routesManager'

type FilterParams = AgenciesParams & BrandsParams & ClientsParams & IndustriesParams & MarketsParams & GeographiesParams
export type FetchExportFn = ReturnType<
  typeof createUseFetch<(arg0: FilterParams) => (signal?: AbortSignal) => Promise<AxiosResponse<Blob>>>
>

interface Props extends ComponentProps<typeof WppActionButton> {
  state: FilterState
  fetchExportFn: FetchExportFn
}

export const ExportButton = ({ state, fetchExportFn, ...rest }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const objUrl = useRef<string>()
  const path = pathname.substring(1) as keyof (typeof routesManager)['masterData']
  const { enqueueToast } = useToast()

  const loadExport = fetchExportFn({
    staleTime: 0,
  })

  const [{ loading }, downloadExport] = useAsyncFn(async () => {
    try {
      const { data: blobData } = await loadExport({
        search: state.search,
        ...(!!state.type && { filterType: state.type }),
      })

      objUrl.current = window.URL.createObjectURL(new Blob([blobData]))
      const link = document.createElement('a')
      link.href = objUrl.current
      link.setAttribute('download', `${path}.csv`)
      link.click()
    } catch (err) {
      if (process.env.DEV) {
        console.error(err)
      }
      enqueueToast({
        message: t('common.errors.general'),
        type: 'error',
      })
    }
  }, [loadExport, enqueueToast, state, path, t])

  useEffect(
    () => () => {
      if (objUrl.current) {
        window.URL.revokeObjectURL(objUrl.current!)
      }
    },
    [],
  )

  if (state.isEmpty) return null

  return (
    <WppActionButton loading={loading} variant="primary" onClick={downloadExport} {...rest}>
      <WppIconExport slot="icon-start" />
      {t('common.export')}
    </WppActionButton>
  )
}
