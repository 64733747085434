import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'
import { AttachmentMetadataResponse, MayBeNull } from '@wpp-open/core'
import { ICellRendererParams } from 'ag-grid-community'
import { useTranslation } from 'react-i18next'

import { RadioCustomLabel } from 'components/common/radioCustomLabel/RadioCustomLabel'
import { TableEmptyCell } from 'components/common/table'
import { TableCheckboxCell } from 'components/merge/table/components/TableCheckboxCell'
import { TableRadioCell } from 'components/merge/table/components/TableRadioCell'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { getMinorUnitKeyValue } from 'pages/currencies/utils'
import { CommonTableRowField, ENTRY_NAME } from 'types/masterData/mergeEntities'
import { EntityFormAllFields } from 'types/masterData/utils'
import { PillsLikeField, pillsLikeFields } from 'utils/merge/preparePillsLike'
import { TextLikeField, textLikeFields } from 'utils/merge/prepareTextLike'

type EntryKey = (typeof ENTRY_NAME)[number]
interface Params {
  columnId: keyof Pick<CommonTableRowField, EntryKey>
  formIndex: 0 | 1
}

type FieldKeys = (keyof EntityFormAllFields)[]
const radioBoxLikeField: FieldKeys = ['clientId', 'parentId']
const checkBoxLikeField: FieldKeys = ['clientIds', 'industryIds', 'geographyIds', 'currencyIds']
const readonlyCheckBoxLikeField: FieldKeys = ['children', 'brands']

export const renderCompareColumn =
  ({ columnId, formIndex }: Params) =>
  ({ data, column }: ICellRendererParams<CommonTableRowField>) => {
    const { t } = useTranslation()
    const cellRendererDataParam = data!
    const cellRendererDataObject = cellRendererDataParam[columnId]

    // Render empty cell if no data
    if (!cellRendererDataObject.data && cellRendererDataParam.id !== 'minorUnit') return TableEmptyCell

    if (cellRendererDataParam.id === 'logoThumbnail') {
      const payload = cellRendererDataObject.data as unknown as AttachmentMetadataResponse
      return (
        <RadioCustomLabel flexAlign="start" value={payload?.key} name={`${cellRendererDataParam.id}`} required>
          <img className={styles.logo} alt="Logo" src={payload?.url!} />
        </RadioCustomLabel>
      )
    }

    // multiple select text-based
    if (pillsLikeFields.includes(cellRendererDataParam.id as PillsLikeField)) {
      const payload = cellRendererDataObject.data as unknown as AutocompleteDefaultOption
      return (
        <TableCheckboxCell
          label={payload.label}
          cellWidth={column?.getActualWidth()}
          isSameValue={cellRendererDataObject.isSameValue}
          formIndex={formIndex}
          name={`${cellRendererDataParam.id}.${formIndex}.${cellRendererDataParam.index}`}
          data-testid={`compare-checkbox-${cellRendererDataParam.id}`}
        />
      )
    }

    // id-based value, single select
    if (radioBoxLikeField.includes(cellRendererDataParam.id)) {
      const payload = cellRendererDataObject.data as unknown as AutocompleteDefaultOption
      return (
        <TableRadioCell
          cellWidth={column?.getActualWidth()}
          name={cellRendererDataParam.id}
          disabled={cellRendererDataObject.isSameValue}
          value={payload?.id}
          label={payload?.label}
        />
      )
    }

    if (cellRendererDataParam.id === 'minorUnit') {
      const payload = cellRendererDataObject.data as unknown as MayBeNull<number>
      return (
        <TableRadioCell
          cellWidth={column?.getActualWidth()}
          disabled={cellRendererDataObject.isSameValue}
          label={t(`master_data.currencies.minor_unit.${getMinorUnitKeyValue(payload).toLocaleLowerCase()}`)}
          value={getMinorUnitKeyValue(payload)}
          name={cellRendererDataParam.id}
        />
      )
    }

    // index-based readonly multiple select
    if (readonlyCheckBoxLikeField.includes(cellRendererDataParam.id)) {
      const payload = cellRendererDataObject.data as unknown as AutocompleteDefaultOption
      return (
        <TableCheckboxCell
          cellWidth={column?.getActualWidth()}
          label={payload?.label}
          name={`${cellRendererDataParam.id}.${formIndex}.${cellRendererDataParam.index}`}
          disabled // readonly
        />
      )
    }

    // text-based value, single select
    if (textLikeFields.includes(cellRendererDataParam.id as TextLikeField)) {
      const payload = cellRendererDataObject.data as unknown as string
      return (
        <TableRadioCell
          cellWidth={column?.getActualWidth()}
          disabled={cellRendererDataObject.isSameValue}
          label={payload}
          name={cellRendererDataParam.id}
        />
      )
    }

    // index-based multiple select
    if (checkBoxLikeField.includes(cellRendererDataParam.id)) {
      const payload = cellRendererDataObject.data as unknown as AutocompleteDefaultOption
      return (
        <TableCheckboxCell
          cellWidth={column?.getActualWidth()}
          name={`${cellRendererDataParam.id}.${formIndex}.${cellRendererDataParam.index}`}
          label={payload?.label}
          value={payload?.id}
          disabled={cellRendererDataObject.isSameValue}
        />
      )
    }
  }
