import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ReadonlyPills } from 'components/common/readonlyPills/ReadonlyPills'
import { EntityLogo } from 'pages/components/mergeEntityWrapper/components/EntityLogo'
import { EntityPills } from 'pages/components/mergeEntityWrapper/components/EntityPills'
import { EntityTextPreview } from 'pages/components/mergeEntityWrapper/components/EntityTextPreview'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { MergeMarketsForm } from 'pages/markets/merge/form/types'
import { convertFormToPreview } from 'pages/markets/merge/preview-utils'
import { useGetParentIdName } from 'pages/markets/merge/useGetParentIdName'
import { Market } from 'types/masterData/markets'
import { MergeCardProps } from 'types/masterData/mergeEntities'

export const PreviewResult = ({ data, ...rest }: MergeCardProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<MergeMarketsForm>()
  const formValues = watch()
  const parentIdName = useGetParentIdName({ data })
  const preview = convertFormToPreview({ values: formValues, data: data as Market[] })

  return (
    <WppCard {...rest} data-testid="preview-result">
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.preview', { order: 3 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.preview')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        <EntityLogo src={preview.logoThumbnail?.url!} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.entity_name', {
            entity: t('common.master_data.entities.market'),
          })}
          text={preview.name!}
          hasEmptyState
        />

        <EntityPills data={preview.aliases!} label={t('master_data.merge_entities.fields.aliases')} />

        <EntityTextPreview label={parentIdName} text={preview.parent?.name!} />

        <EntityTextPreview label={t('master_data.merge_entities.fields.isoAlpha2')} text={preview.isoAlpha2} />

        <EntityTextPreview label={t('master_data.merge_entities.fields.isoAlpha3')} text={preview.isoAlpha3} />

        <ReadonlyPills
          labelConfig={{ text: t('master_data.merge_entities.fields.geographyIds') }}
          navigationTo="geographies"
          value={preview.geographies}
        />

        {!!preview?.currencies && (
          <ReadonlyPills
            labelConfig={{ text: t('master_data.merge_entities.fields.currencyIds') }}
            navigationTo="currencies"
            value={preview.currencies}
          />
        )}
      </Flex>
    </WppCard>
  )
}
