import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { UseQueryReturnType } from 'api/common/types'
import { useFetchIndustryApi } from 'api/masterData/queries/useFetchIndustryApi'
import { Flex } from 'components/common/flex/Flex'
import { FormIndustriesAutocomplete } from 'components/form/formAutocomplete/FormIndustriesAutocomplete'
import { FormInputAutocomplete } from 'components/form/formAutocomplete/FormInputAutocomplete'
import { FormReadonlyPills } from 'components/form/formReadonlyPills/FormReadonlyPills'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { useForm } from 'hooks/form/useForm'
import { EntityDetailsCards } from 'pages/components/entityDetailsCards/EntityDetailsCards'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { ActionButtons } from 'pages/industries/upsert/components/ActionButtons'
import { defaultValues, useIndustryValidationScheme, convertToForm } from 'pages/industries/upsert/utils'
import { Industry } from 'types/masterData/industries'

export const UpsertIndustry = () => {
  const { t } = useTranslation()

  const { entryId } = useParams()
  const { state } = useLocation()
  const industryState = state?.data as MayBeNull<Industry>

  const query = useFetchIndustryApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const industry = query.data || industryState

  const form = useForm({
    defaultValues: industry ? convertToForm(industry) : defaultValues,
    validationSchema: useIndustryValidationScheme(),
  })

  const {
    contextState: { selectedVersionFields, nonCurrentVersionSelected },
  } = useUpsertEntityContext()

  const shouldDisableFields = nonCurrentVersionSelected || query.isLoading

  return (
    <div data-testid="upsert-industry">
      <FormProvider {...form}>
        <UpsertEntityWrapper
          query={query as UseQueryReturnType}
          title={t(`common.${industry ? 'update' : 'create'}_entity`, {
            entity: t('common.master_data.entities.industry'),
          })}
          actionButtons={<ActionButtons form={form} industry={industry!} />}
          rightCards={industry && <EntityDetailsCards entity={industry} />}
          data-testid="upsert-entity-wrapper"
        >
          <Flex direction="column" gap={24}>
            <FormTextInput
              tagMessage={selectedVersionFields.name}
              disabled={shouldDisableFields}
              name="name"
              labelConfig={{
                text: t('master_data.fields.name.label'),
                description: t('master_data.fields.name.hint'),
              }}
              required
              data-testid="name"
            />
            <FormInputAutocomplete
              tagMessage={selectedVersionFields.aliases}
              disabled={shouldDisableFields}
              labelConfig={{
                text: t('master_data.fields.aliases.label'),
                description: t('master_data.fields.aliases.hint'),
              }}
              name="aliases"
            />
            <FormReadonlyPills
              disabled={shouldDisableFields}
              tagMessage={selectedVersionFields.brands}
              navigationTo="industries"
              labelConfig={{
                text: t('master_data.fields.subsidiaries.label'),
                description: t('master_data.fields.subsidiaries.hint', {
                  entity: t('common.master_data.entities.industry'),
                  entities: t('common.master_data.entities.industry', { count: 2 }),
                }),
              }}
              name="children"
              getItemLabel={item => item.name}
            />
            <FormIndustriesAutocomplete
              tagMessage={selectedVersionFields.parentId}
              disabled={shouldDisableFields}
              name="parentId"
              multiple={false}
              labelConfig={{
                text: t('master_data.fields.parent.label', {
                  entity: t('common.master_data.lowercase_entities.industry'),
                }),
                description: t('master_data.fields.parent.hint', {
                  entity: t('common.master_data.lowercase_entities.industry'),
                  entities: t('common.master_data.lowercase_entities.industry', { count: 2 }),
                }),
              }}
            />
          </Flex>
        </UpsertEntityWrapper>
      </FormProvider>
    </div>
  )
}
