import { WppInput } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useSetState } from 'react-use'

import { useFetchGeographiesExportApi } from 'api/masterData/queries/useFetchGeographiesExportApi'
import { HeaderActionsList } from 'components/merge/list/headerActionsList/HeaderActionsList'
import { TableKey } from 'constants/table'
import { useDebounceSetSearch } from 'hooks/form/useDebounceSetSearch'
import { TablePageActionButtons } from 'pages/components/tablePageActionButtons/TablePageActionButtons'
import { TablePageWrapper } from 'pages/components/tablePageWrapper/TablePageWrapper'
import styles from 'pages/components/tablePageWrapper/TablePageWrapper.module.scss'
import { TableListGeographies } from 'pages/geographies/list/TableListGeographies'
import { CommonFilterState, MergeFilterState } from 'types/masterData/utils'

export const ListGeographies = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const stateObj = useSetState<CommonFilterState<MergeFilterState>>({
    isEmpty: false,
    isEmptySearch: false,
    search: searchParams.get('search') || '',
    searchInput: searchParams.get('search') || '',
    selectedRows: [],
  })
  const [state, setState] = stateObj
  const handleSearch = useDebounceSetSearch({ setState })

  return (
    <TablePageWrapper
      actionButtons={
        <TablePageActionButtons
          state={state}
          fetchExportFn={useFetchGeographiesExportApi}
          hasResults={!state.isEmpty}
        />
      }
    >
      <div slot="header">
        <WppInput
          size="s"
          type="search"
          value={state.searchInput}
          onWppChange={handleSearch}
          className={styles.searchInput}
          placeholder={t('common.search')}
          data-testid="geography-search-input"
        />
      </div>
      <HeaderActionsList
        slot="actions"
        stateObj={stateObj}
        tableKey={TableKey.MASTER_DATA_GEOGRAPHIES}
        module="geographies"
      />
      <TableListGeographies stateObj={stateObj} />
    </TablePageWrapper>
  )
}
