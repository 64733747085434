import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'

import { MergeableEntity } from 'types/masterData/mergeEntities'
import { EntityFormAllFields, EntityFormLikeAllFields } from 'types/masterData/utils'
import { mergeArrays } from 'utils/common'

export const idBasedFields = [
  'geographyIds',
  'currencyIds',
  'industryIds',
  'clientIds',
  'children',
  'brands',
] as const satisfies (keyof EntityFormLikeAllFields)[]
export type IdBasedFields = typeof idBasedFields
export type IdBasedField = IdBasedFields[number]

interface Props<T extends EntityFormLikeAllFields, F extends IdBasedField> {
  entries: T[]
  extractFn: (arg: T) => AutocompleteDefaultOption[]
  field: F
}

export const prepareIdBasedPills = <T extends EntityFormLikeAllFields, F extends IdBasedField>({
  entries: [entry1, entry2],
  extractFn,
  field,
}: Props<T, F>) => {
  const entityIds1 = extractFn(entry1)
  const entityIds2 = extractFn(entry2)

  const commonValues = entityIds1?.filter(item1 => entityIds2?.find(item2 => item1?.id === item2?.id)) || []

  const sortedEntities1 = [
    ...commonValues,
    ...(entityIds1 || []).filter(item1 => !commonValues.find(commonItem => item1?.id === commonItem?.id)),
  ]
  const sortedEntities2 = [
    ...commonValues,
    ...(entityIds2 || []).filter(item2 => !commonValues.find(commonItem => item2?.id === commonItem?.id)),
  ]

  const mergedEntitiesArr = mergeArrays(sortedEntities1, sortedEntities2)

  return mergedEntitiesArr.map(
    ([entity1, entity2], index) =>
      ({
        id: field as F,
        index,
        entry1: {
          data: entity1!,
          isSameValue: !!commonValues.find(commonValue => commonValue.id === entity1?.id),
          entryName: entry1?.name! || entry1?.displayName!,
        },
        entry2: {
          data: entity2!,
          isSameValue: !!commonValues.find(commonValue => commonValue.id === entity2?.id),
          entryName: entry2?.name! || entry1?.displayName!,
        },
      }) as const satisfies MergeableEntity<EntityFormAllFields>,
  )
}
