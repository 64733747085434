import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ReadonlyPills } from 'components/common/readonlyPills/ReadonlyPills'
import { MergeAgenciesForm } from 'pages/agencies/merge/form/types'
import { convertFormToPreview } from 'pages/agencies/merge/preview-utils'
import { EntityLogo } from 'pages/components/mergeEntityWrapper/components/EntityLogo'
import { EntityPills } from 'pages/components/mergeEntityWrapper/components/EntityPills'
import { EntityTextPreview } from 'pages/components/mergeEntityWrapper/components/EntityTextPreview'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { Agency } from 'types/masterData/agencies'
import { MergeCardProps } from 'types/masterData/mergeEntities'

export const PreviewResult = ({ data, clientsData, ...rest }: MergeCardProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<MergeAgenciesForm>()
  const formValues = watch()

  const preview = convertFormToPreview({ values: formValues, data: data as Agency[], clientsData: clientsData! })

  return (
    <WppCard {...rest} data-testid="preview-result">
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.preview', { order: 3 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.preview')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        <EntityLogo src={preview.logoThumbnail?.url!} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.entity_name', {
            entity: t('common.master_data.entities.agency'),
          })}
          text={preview.displayName}
          hasEmptyState
        />

        <EntityPills data={preview.aliases!} label={t('master_data.merge_entities.fields.aliases')} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.shortDescription')}
          text={preview.shortDescription}
        />

        <EntityTextPreview label={t('master_data.merge_entities.fields.description')} text={preview.description} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.parent_entity', {
            entity: t('common.master_data.entities.agency'),
          })}
          text={preview.parent?.displayName!}
        />

        <EntityTextPreview label={t('master_data.merge_entities.fields.serviceName')} text={preview.serviceName} />

        <EntityTextPreview label={t('master_data.merge_entities.fields.navigatorUrl')} text={preview.navigatorUrl} />

        <EntityPills data={preview.domains} label={t('master_data.merge_entities.fields.domains')} />

        <ReadonlyPills
          pillMaxLength={42}
          labelConfig={{ text: t('master_data.merge_entities.fields.clientIds') }}
          value={preview.clients}
          navigationTo="clients"
        />
      </Flex>
    </WppCard>
  )
}
