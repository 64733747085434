import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFetchAgencyApi } from 'api/masterData/queries/useFetchAgencyApi'
import { useForm } from 'hooks/form/useForm'
import { QueryFn, useFetchTwoEntities } from 'hooks/masterData/useFetchTwoEntities'
import { ActionButtons } from 'pages/agencies/merge/components/ActionButtons'
import { CompareEntities } from 'pages/agencies/merge/components/CompareEntities'
import { PreviewResult } from 'pages/agencies/merge/components/PreviewResult'
import { SelectMainEntity } from 'pages/agencies/merge/components/SelectMainEntity'
import { defaultFormValues } from 'pages/agencies/merge/form/defaultValues'
import { useAgenciesMergeValidationScheme } from 'pages/agencies/merge/form/formValidation'
import { MergeAgenciesValues } from 'pages/agencies/merge/form/types'
import { defaultTwoEntityValues } from 'pages/agencies/merge/twoEntities/defaultValues'
import { convertToTwoEntityValues } from 'pages/agencies/merge/twoEntities/utils'
import { convertToForm } from 'pages/agencies/merge/utils'
import { MergeEntityWrapper } from 'pages/components/mergeEntityWrapper/MergeEntityWrapper'
import { Agency } from 'types/masterData/agencies'

export const MergeAgencies = () => {
  const { t } = useTranslation()
  const fetchAgencies = useFetchTwoEntities<Agency, MergeAgenciesValues>({
    queryFn: useFetchAgencyApi as QueryFn<Agency>,
    getValuesFn: ({ isSuccess, data, clientsData }) =>
      isSuccess ? convertToTwoEntityValues(data, clientsData!) : defaultTwoEntityValues,
  })
  const { data, isSuccess } = fetchAgencies

  const form = useForm({
    defaultValues: isSuccess ? convertToForm(data) : defaultFormValues,
    validationSchema: useAgenciesMergeValidationScheme(),
  })

  return (
    <FormProvider {...form}>
      <MergeEntityWrapper
        title={t('master_data.merge_entities.merge_title', {
          entity: t('common.master_data.lowercase_entities.agency_other'),
        })}
        actionButtons={<ActionButtons data={data} form={form} clientsData={fetchAgencies.clientsData} />}
        fetchEntities={fetchAgencies}
        selectMainEntity={SelectMainEntity}
        previewResult={PreviewResult}
        compareEntities={CompareEntities}
        data-testid="merge-entity-wrapper"
      />
    </FormProvider>
  )
}
