import { AutocompleteDefaultOption } from '@platform-ui-kit/components-library'

import { MergeableEntity } from 'types/masterData/mergeEntities'
import { EntityAllFields, EntityFormAllFields } from 'types/masterData/utils'
import { mergeArrays } from 'utils/common'

export const pillsLikeFields = ['aliases', 'domains'] as const satisfies (keyof EntityAllFields)[]
type PillsLikeFields = typeof pillsLikeFields
export type PillsLikeField = PillsLikeFields[number]

const parsePillsLike = (arr?: string[]): AutocompleteDefaultOption[] =>
  (arr || []).map(item => ({ id: item, label: item }))

interface Props<T extends EntityAllFields, F extends PillsLikeField> {
  entries: T[]
  field: F
}

export const preparePillsLike = <T extends EntityAllFields, F extends PillsLikeField>({
  entries: [entry1, entry2],
  field,
}: Props<T, F>) => {
  if (!entry1[field]?.length && !entry2[field]?.length) return []

  const entity1: string[] = (entry1[field] || []).sort((a, b) => a.localeCompare(b))
  const entity2: string[] = (entry2[field] || []).sort((a, b) => a.localeCompare(b))

  const commonValues = entity1?.filter(item => entity2?.includes(item)) || []

  const sortedEntities1 = [...commonValues, ...(entity1 || []).filter(item => !commonValues.includes(item))]
  const sortedEntities2 = [...commonValues, ...(entity2 || []).filter(item => !commonValues.includes(item))]

  const mergedEntitiesArr = mergeArrays(parsePillsLike(sortedEntities1), parsePillsLike(sortedEntities2))
  return mergedEntitiesArr.map(
    ([entity1, entity2], index) =>
      ({
        id: field as F,
        index,
        entry1: {
          data: entity1!,
          isSameValue: commonValues.includes(entity1?.label!),
          entryName: entry1?.name! || entry1?.displayName!,
        },
        entry2: {
          data: entity2!,
          isSameValue: commonValues.includes(entity2?.label!),
          entryName: entry2?.name! || entry1?.displayName!,
        },
      }) as const satisfies MergeableEntity<EntityFormAllFields>,
  )
}
