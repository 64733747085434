import { WppButton } from '@platform-ui-kit/components-library-react'
import { FieldErrors, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useMergeBrandsApi } from 'api/masterData/mutations/useMergeBrandsApi'
import { MergeBrandsForm } from 'pages/brands/merge/form/types'
import { convertFormToFormDto } from 'pages/brands/merge/utils'
import { handleReloadBrandsListTable } from 'pages/brands/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Brand } from 'types/masterData/brands'
import { routesManager } from 'utils/routesManager'

interface Props {
  form: UseFormReturn<MergeBrandsForm>
  data: Brand[]
}

export const ActionButtons = ({ form, data }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const navigate = useNavigate()
  const { mutateAsync: handleMerge } = useMergeBrandsApi()
  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset,
  } = form

  const onMergeValidationFail = (errors: FieldErrors<MergeBrandsForm>) => {
    if (errors.clientId) {
      return enqueueToast({
        message: t('master_data.merge_entities.validation.brands_client_id_error'),
      })
    }
  }

  const onMergeSubmit = async (values: MergeBrandsForm) => {
    try {
      const mainEntity = data.find(entity => entity.name === values.name)
      const secondaryEntity = data.find(entity => entity.name !== values.name)

      await handleMerge({
        mainId: mainEntity?.id!,
        secondaryId: secondaryEntity?.id!,
        brand: convertFormToFormDto({ values, data }),
      })
      enqueueToast({
        message: t('master_data.merge_entities.notification.success', {
          entity1: mainEntity?.name,
          entity2: secondaryEntity?.name,
        }),
        type: 'success',
      })

      await handleReloadBrandsListTable()
      navigate(routesManager.masterData.brands.update.getURL({ entryId: mainEntity?.id! }))
    } catch {
      enqueueToast({
        message: t('common.errors.general'),
        type: 'error',
      })
    }
  }

  return (
    <>
      <WppButton variant="secondary" disabled={!isDirty} onClick={() => reset()}>
        {t('common.reset')}
      </WppButton>
      <WppButton
        loading={isSubmitting}
        variant="primary"
        onClick={() => handleSubmit(onMergeSubmit, onMergeValidationFail)()}
        data-testid="merge-brands-button"
      >
        {t('master_data.merge_entities.action_button')}
      </WppButton>
    </>
  )
}
