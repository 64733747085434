import { useOs } from '@wpp-open/react'
import { differenceInDays, format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import i18next from 'i18next'
import { useCallback } from 'react'

import { DateFormat, TIME_FORMAT } from 'types/common/dates'

export const useDateFormat = () => {
  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()

  return {
    formatDate: useCallback(
      (dateToFormat: string, withTime: boolean = true) => {
        if (!dateToFormat) return null
        const locale = `${DateFormat[dateLocale as keyof typeof DateFormat]}${withTime ? ` ${TIME_FORMAT}` : ''}`

        const date = parseISO(dateToFormat)
        const daysDiff = differenceInDays(date, Date.now())

        if (daysDiff === 0) {
          return `${formatDistanceToNowStrict(date)} ${i18next.t('common.label_date_ago')}`
        }

        return format(date, locale)
      },
      [dateLocale],
    ),
  }
}
