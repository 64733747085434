import { WppActionButton, WppButton, WppIconTrash } from '@platform-ui-kit/components-library-react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCreateIndustryApi } from 'api/masterData/mutations/useCreateIndustryApi'
import { useEditIndustryApi } from 'api/masterData/mutations/useEditIndustryApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { showDeleteIndustryModal } from 'pages/industries/deleteIndustryModal/DeleteIndustryModal'
import { convertFormToFormDto } from 'pages/industries/upsert/utils'
import { handleReloadIndustriesListTable } from 'pages/industries/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Industry, IndustryForm } from 'types/masterData/industries'
import { routesManager } from 'utils/routesManager'

interface Props {
  industry: Industry
  form: UseFormReturn<IndustryForm>
}

export const ActionButtons = ({ industry, form }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const {
    contextState: { nonCurrentVersionSelected },
    handleSelectVersion,
  } = useUpsertEntityContext()
  const { mutateAsync: handleCreate } = useCreateIndustryApi()
  const { mutateAsync: handleEdit } = useEditIndustryApi()
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: IndustryForm) => {
    try {
      const { data: createdIndustry } = await handleCreate({
        industry: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.industries.update.getURL({ entryId: createdIndustry?.id }))

      handleSelectVersion({ version: null })
      await Promise.all([
        handleReloadIndustriesListTable(industry?.id),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY] }),
      ])

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.industry') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: IndustryForm) => {
    try {
      await handleEdit({
        id: industry!.id,
        industry: convertFormToFormDto(values),
      })

      handleSelectVersion({ version: null })
      await Promise.all([
        handleReloadIndustriesListTable(industry!.id),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY] }),
      ])

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.industry') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteIndustryModal({
      industry: industry!,
      onAfterDelete: () => {
        navigate(routesManager.masterData.industries.root.getURL())
      },
    })
  }

  return (
    <>
      {industry && (
        <WppActionButton
          variant="destructive"
          disabled={nonCurrentVersionSelected}
          onClick={handleDelete}
          data-testid="delete-industry"
        >
          <WppIconTrash slot="icon-start" />
          {t('common.delete')}
        </WppActionButton>
      )}
      <WppButton
        variant="secondary"
        disabled={!isDirty || nonCurrentVersionSelected}
        onClick={() => reset()}
        data-testid="reset-industry"
      >
        {t('common.reset')}
      </WppButton>
      <WppButton
        loading={isSubmitting}
        variant="primary"
        disabled={nonCurrentVersionSelected}
        onClick={() => handleSubmit(industry ? onEditSubmit : onCreateSubmit)()}
        data-testid="save-industry"
      >
        {t('common.save')}
      </WppButton>
    </>
  )
}
