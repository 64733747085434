import { WppButton, WppIconWarning, WppTypography } from '@platform-ui-kit/components-library-react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { useEditAgencyApi } from 'api/masterData/mutations/useEditAgencyApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { convertFormToFormDto } from 'pages/agencies/upsert/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Agency, AgencyForm, AgencyFormDTO } from 'types/masterData/agencies'
import { Client } from 'types/masterData/clients'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  agencyId: Agency['id']
  client: Client
  form: UseFormReturn<AgencyForm>
}

const RemoveClientFromAgencyModal = ({ id, agencyId, client, form, isOpen, onClose, onCloseComplete }: Props) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleEdit } = useEditAgencyApi()
  const { handleSubmit, getValues, setValue } = form
  const prevSelectedClientIds = getValues('clientIds')

  const [{ loading: isSubmitting }, onSubmit] = useAsyncFn(
    async (values: AgencyForm) => {
      try {
        const selectedClients = prevSelectedClientIds.filter(item => item !== client.id)
        const agencyValues: AgencyFormDTO = {
          ...convertFormToFormDto(values),
          clientIds: selectedClients,
        }

        await handleEdit({
          id: agencyId,
          agency: agencyValues,
        })
        setValue('clientIds', selectedClients)

        await queryClient.invalidateQueries({
          queryKey: [ApiQueryKeys.MASTER_DATA_CLIENTS, { agencyId: [agencyId] }],
        })
        tableActions.reload(TableKey.MASTER_DATA_CLIENTS_AGENCY)
        onClose()

        enqueueToast({
          message: t('common.toasts.delete_from', {
            entity: t('common.master_data.entities.client'),
            deleteFrom: t('common.master_data.entities.agency'),
            is: t('common.is'),
          }),
          type: 'success',
        })
      } catch {
        enqueueToast({
          message: t('common.errors.general'),
          type: 'error',
        })
      }
    },
    [onClose, prevSelectedClientIds, client, handleEdit, agencyId, enqueueToast, t, setValue],
  )

  return (
    <Modal
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isSubmitting}
      data-testid={id}
    >
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          <WppIconWarning color="var(--wpp-danger-color-500)" />
          {t('master_data.remove_item_from_list.header', {
            entity: t('common.master_data.lowercase_entities.client'),
          })}
        </Flex>
      </WppTypography>

      <WppTypography slot="body" type="s-body">
        {t('master_data.remove_item_from_list.description', {
          entity: t('common.master_data.lowercase_entities.client'),
        })}
      </WppTypography>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s" disabled={isSubmitting} data-testid="cancel">
          {t('common.cancel')}
        </WppButton>
        <WppButton
          onClick={() => handleSubmit(onSubmit)()}
          size="s"
          variant="destructive"
          loading={isSubmitting}
          data-testid="apply"
        >
          {t('common.remove')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showRemoveClientFromAgencyModal } = createNiceModal(
  RemoveClientFromAgencyModal,
  'remove-client-from-agency-modal',
)
