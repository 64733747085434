import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ReadonlyPills } from 'components/common/readonlyPills/ReadonlyPills'
import { MergeBrandsForm } from 'pages/brands/merge/form/types'
import { convertFormToPreview } from 'pages/brands/merge/preview-utils'
import { EntityLogo } from 'pages/components/mergeEntityWrapper/components/EntityLogo'
import { EntityPills } from 'pages/components/mergeEntityWrapper/components/EntityPills'
import { EntityTextPreview } from 'pages/components/mergeEntityWrapper/components/EntityTextPreview'
import styles from 'pages/components/mergeEntityWrapper/MergeEntityWrapper.module.scss'
import { Brand } from 'types/masterData/brands'
import { MergeCardProps } from 'types/masterData/mergeEntities'

export const PreviewResult = ({ data, ...rest }: MergeCardProps) => {
  const { t } = useTranslation()
  const { watch } = useFormContext<MergeBrandsForm>()
  const formValues = watch()

  const preview = convertFormToPreview({ values: formValues, data: data as Brand[] })

  return (
    <WppCard {...rest} data-testid="preview-result">
      <WppTypography type="l-strong" slot="header">
        {t('master_data.merge_entities.titles.preview', { order: 3 })}
      </WppTypography>
      <WppTypography type="m-body" className={styles.descriptionText}>
        {t('master_data.merge_entities.descriptions.preview')}
      </WppTypography>
      <Flex direction="column" gap={24}>
        <EntityLogo src={preview.logoThumbnail?.url!} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.entity_name', {
            entity: t('common.master_data.entities.brand'),
          })}
          text={preview.name!}
          hasEmptyState
        />

        <EntityPills data={preview.aliases!} label={t('master_data.merge_entities.fields.aliases')} />

        <EntityTextPreview label={t('master_data.merge_entities.fields.clientId')} text={preview.client?.name!} />

        <EntityTextPreview
          label={t('master_data.merge_entities.fields.parent_entity', {
            entity: t('common.master_data.entities.brand'),
          })}
          text={preview.parent?.name!}
        />

        <ReadonlyPills
          labelConfig={{ text: t('master_data.merge_entities.fields.industryIds') }}
          navigationTo="industries"
          value={preview.industries!}
        />

        <ReadonlyPills
          labelConfig={{ text: t('master_data.merge_entities.fields.children') }}
          value={preview.children!}
          navigationTo="brands"
        />
      </Flex>
    </WppCard>
  )
}
